/** @jsx jsx */
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { SanityRegion } from '@bottlebooks/gatsby-theme-cms/src/components';

export default function RegionDetailTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const locale = data?.site?.siteMetadata?.locales?.[0]?.locale || 'en';
  const {
    countryCode,
    regionCode,
    subregionCodes = [],
  } = data.sanityRegion.regionHierarchy || {};
  return (
    <SanityRegion
      {...data.sanityRegion}
      regionHierarchy={[countryCode, regionCode, ...subregionCodes].filter(
        Boolean
      )}
      locale={locale}
    />
  );
}

export const pageQuery = graphql`
  query test_RegionTemplate($id: String!) {
    sanityRegion(_id: { eq: $id }) {
      ...SanityRegion
    }
    site {
      siteMetadata {
        title
        locales {
          locale
        }
      }
    }
  }
`;
